.root {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 1rem;
  padding: 1rem;
  box-shadow: 1px 1px 4px #ccc;
  border-radius: 3px;
}

.content {
  flex-grow: 1;
  margin: -0.5rem;
}

.row {
  padding: 0.5rem;
}
