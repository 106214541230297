//screens
$small-phone: 320px;
$small-phone-landscape: 599px;
$phone: 768px;
$tablet: 1023px;
$laptop: 1200px;
$desktop: 1600px;

// z-indexes
$z-alert: 10010;
