.table {
  margin: 0;
}

.table__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
@media (max-width: 767px) {
  .table__header {
    flex-direction: column;
  }
}

.table__subHeader {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.table__body {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.table__actions {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 767px) {
  .table__actions {
    margin-top: 0.5rem;
    justify-content: flex-start;
  }
}
