@import 'styles/index';

.root {
  position: relative;
  padding: 3.1em 2.5rem 0 11.9rem;
  box-sizing: border-box;

  @include media(small-phone-landscape) {
    padding: 4.35em 1rem 0;
  }
}
