body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
}

input[type='file'] {
  /* height: 42px;
  width: 100%; */
  cursor: pointer;
}

input[type='file']::-webkit-file-upload-button {
  display: none;
}

input {
  border-radius: 4px;
  border: 1px solid #ced4da;
}

input:focus,
select:focus {
  outline: none;
  border-color: #6265e4;
}

.asBlock {
  display: block !important;
}

.datepicker-portal {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 328px;
}

.datepicker-portal,
.MuiAutocomplete-popper {
  z-index: 10002 !important; /* autocomplete with modal*/
}

.alert-app {
  position: absolute;
  top: 10px;
  right: 50px;
  margin-left: 150px;
  max-width: 100%;
  z-index: 11000;
}

@media (max-width: 599px) {
  .alert-app {
    top: 70px;
    margin-left: 0;
  }
}
