@import 'styles/index';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
}

.container {
  display: flex;
  flex-grow: 1;
  padding: 0 2rem;

  @include media(phone) {
    padding: 0 1rem;
  }
}

.iconWrap {
  font-size: 10rem;
  line-height: 1;
  padding-right: 2rem;

  @include media(phone) {
    font-size: 5rem;
    padding-right: 1rem;
  }
}
.icon {
  vertical-align: top;
}

.content {
  display: block;
}
