.LinearProgress {
  border: 1px solid #ccc;
}

.LinearProgress__value {
  display: inline-block;
  text-align: center;
  padding: 0.1rem;
  background-color: #ccc;
}
