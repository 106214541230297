.PollsResultsView__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.PollsResultsView__row:first-child {
  margin-top: 0;
}
.PollsResultsView__row:last-child {
  margin-bottom: 0;
}

.PollsResultsView__graph {
  display: flex;
  flex-direction: column;
  background-color: aliceblue;
  padding: 0.5rem 2rem;
}
.PollsResultsView__title {
  text-align: center;
  font-size: 1.4rem;
}
.PollsResultsView__item {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.PollsResultsView__name,
.PollsResultsView__value {
  flex-basis: 45%;
}

.PollsResultsView__value {
  flex-shrink: 0;
}

.PollsResultsView__line {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PollsResultsView__additions {
  margin-top: 0.5rem;
}
