.categoryAvatar {
  width: 50px;
  height: 50px;
  margin: 5px 0 5px 0;
}

.categories-page {
  margin: 0 50px 0 0;
}

@media (max-width: 1024px) {
  .categories-page {
    margin: 0;
  }
}

@media (max-width: 1024px) {
  .categoryAvatar {
    width: 30px;
    height: 30px;
  }

  .rdt_TableHeader {
    display: none !important;
  }
}
