@import 'styles/index.scss';

.root {
  display: block;
}

.input {
  display: none;
}

.fileList {
  flex-grow: 1;
}

.fileItem {
  display: flex;
  align-items: center;
  border: 1px solid #f1f1f1;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.2rem;

  @include media('phone') {
    justify-content: space-between;
  }
}

.fileImage,
.filePreview {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border: 1px solid #dcdcdc;
}

.filePreview {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.fileName {
  padding: 0.2rem 1rem;
  flex-grow: 1;

  @include media('phone') {
    display: none;
  }
}

.fileActions {
  white-space: nowrap;
}

.buttonIcon {
  margin-top: -5px;
  margin-left: 5px;
}

.label {
  display: inline-block;
  margin: 0;

  &_main {
    display: block;
  }
}

.button {
  display: block;
}
