.form {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}

.formRow {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  @extend .formRow;
}
