.Input {
  position: relative;
  width: 100%;
  min-width: 100px;
  font-family: var(--font-family-sans-serif);
}

.Input__control {
  position: relative;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  color: var(--dark);
  font-size: 16px;
  line-height: 1.25;
}

.Input__control:hover:not(.Input__control_error):not(.Input__control_focus):not(.Input__control_disabled) {
  border-color: var(--dark);
}

.Input__control_focus {
  border-width: 2px;
}
.Input__control_focus:not(.Input__control_error) {
  border-color: #3f51b5;
}

.Input__control_error {
  border-color: var(--red);
}
.Input__control_disabled {
  color: rgba(0, 0, 0, 0.38);
}

.Input__label {
  font-size: 16px;
  line-height: 1.25;
  margin-bottom: 0.5rem;
}

.Input__label_disabled {
  color: rgba(0, 0, 0, 0.38);
}

.Input__placeholder {
  position: absolute;
  top: 11px;
  left: 10px;
  padding-left: 4px;
  padding-right: 4px;
  display: flex;
  overflow: hidden;
  color: var(--gray);
  pointer-events: none;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  transform-origin: top left;
  transition: transform 0.3s;
  transform-origin: top left;
  background-color: #fff;
}
.Input__placeholder_filled {
  transition: transform 0.3s;
  transform: scale(0.857) translateY(-22px);
}
.Input__placeholder_disabled {
  color: inherit;
}
.Input__placeholder_focus {
  top: 10px;
  left: 9px;
  color: #3f51b5;
}
.Input__mark,
.Input__placeholder_error {
  color: var(--red);
}

.Input__field {
  display: block;
  width: 100%;
  border-radius: 4px;
  border: none;
  padding: 9px 15px;
  margin: 0;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  background-color: transparent;
  text-overflow: ellipsis;
  resize: none;
  box-sizing: border-box;
}
.Input__field_focus {
  padding: 8px 14px;
  outline: none;
}
.Input__field[type='file'] {
  padding-top: 7px;
  padding-bottom: 6px;
  height: 38px;
  line-height: 1.5;
}
.Input__field[type='file'].Input__field_focus {
  padding-top: 6px;
  padding-bottom: 5px;
  height: 36px;
}

.Input__field:-webkit-autofill,
.Input__field:-webkit-autofill:hover,
.Input__field:-webkit-autofill:focus,
.Input__field:-webkit-autofill:active {
  box-shadow: inset 0 0 0 30px #fff !important;
}

.Input__helper {
  margin: 4px 14px 0;
  font-size: 0.75rem;
  color: var(--gray);
}

.Input__helper_error {
  color: var(--red);
}

.Input__icon {
  position: absolute;
  z-index: 1;
  right: 10px;
  top: 50%;
  display: block;
  margin: 0;
  transform: translateY(-50%);
  cursor: pointer;
}
