.upload-partner-logo {
  white-space: nowrap;
  overflow: hidden;
  width: 70%;
  text-overflow: ellipsis;
}

.partnerForm {
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem;
}
.partnerForm__row,
.partnerForm__column {
  padding: 0.5rem;
}
.partnerForm__column {
  display: flex;
  flex-basis: 50%;
  align-items: flex-start;
}
@media (max-width: 850px) {
  .partnerForm__column {
    flex-basis: 100%;
  }
}

.partnerForm__interactiveItem {
  display: flex;
  width: 100%;
  align-items: center;
}
.partnerForm__interactiveContent {
  flex-grow: 1;
  margin: -0.5rem;
}
