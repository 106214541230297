@import './variables.scss';

@mixin media($size) {
  @if $size == small-phone {
    @media screen and(max-width: $small-phone) {
      @content;
    }
  } @else if $size == phone {
    @media screen and(max-width: $phone) {
      @content;
    }
  } @else if $size == small-phone-landscape {
    @media screen and(max-width: $small-phone-landscape) {
      @content;
    }
  } @else if $size == tablet-phone {
    @media screen and (max-width: $tablet) {
      @content;
    }
  } @else if $size == tablet {
    @media screen and(min-width: $phone) and(max-width: $tablet ) {
      @content;
    }
  } @else if $size == tablet-laptop {
    @media screen and(min-width: $phone) and(max-width: $laptop) {
      @content;
    }
  } @else if $size == laptop {
    @media screen and(min-width: $tablet) and(max-width: $desktop) {
      @content;
    }
  } @else if $size == desktop-laptop {
    @media screen and(min-width: $laptop) {
      @content;
    }
  } @else if $size == desktop {
    @media screen and(min-width: $desktop) {
      @content;
    }
  } @else if $size == xl-only {
    @media (min-width: 1800px) {
      @content;
    }
  }
}

@mixin text-size($font-style, $font-weight, $font-size, $line-height) {
  font-style: $font-style;
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
}
