.root {
  width: 100%;
}

.container {
  display: flex;
  align-items: center;
}

.input {
  margin-left: 1rem;
  margin-right: 1rem;
  flex-grow: 1;
}

.inputFile {
  display: none;
}

.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.label {
  margin: 0;
}
