.root {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 1rem;
}

.content {
  flex-grow: 1;
  margin: -0.5rem;
}

.row {
  padding: 0.5rem;
}
