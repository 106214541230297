@import 'styles/variables.scss';

.root {
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: $z-alert;
  max-width: 100%;
}

.title {
  text-transform: capitalize;
}
