.form {
  display: 'flex';
  flex-direction: 'column';
  position: 'relative';
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  gap: 0.5rem;
}
