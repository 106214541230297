.table-nav-section {
  margin-top: 25px;
  z-index: 1;
}

.partner-transactions-date-filter {
  margin-top: -22px;
}

.date-filter-label {
  margin-right: 15px;
}

.partner-transactions__form {
  display: flex;
  flex-wrap: wrap;
}

.partner-transactions__form-item {
  width: calc(50% - 45px);
  display: flex;
  flex-direction: column;
  margin: 0 30px 30px 0;
  position: relative;
}

.partner-transactions__form-item-label {
  padding-left: 8px;
  font-weight: 500;
}

.partner-transactions__form-item-label-special {
  font-weight: 700;
}

.partner-transactions__form-item-input,
.partner-transactions__form-item-input-money,
.partner-transactions__form-item-input-money-special {
  padding: 8px 15px;
  border-radius: 4px;
  border: 1px solid #ced4da;
}

.partner-transactions__form-item-input:focus,
.partner-transactions__form-item-input-money:focus,
.partner-transactions__form-item-input-money-special:focus {
  outline: none;
  border-color: #6265e4;
}

.partner-transactions__form-item-input-error,
.partner-transactions__form-item-input-money-error,
.partner-transactions__form-item-input-money-special-error {
  position: absolute;
  right: 0;
  bottom: -22px;
  color: red;
}

.partner-transactions__form-item-input[disabled],
.partner-transactions__form-item-input-money[disabled] {
  background-color: #e9ecef;
  color: #212529;
}

.partner-transactions__form-item-input-money-special[disabled] {
  color: #fff;
  background-color: #6265e4;
  font-weight: 500;
}

.partner-transactions__footer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.partner-transactions__form-btns {
  width: calc(50% - 45px);
  display: flex;
  justify-content: center;
  margin: 0 30px 50px 0;
}

.partner-transactions__form-btns-item {
  margin-right: 30px;
}

.partner-transactions__form-btns-item:last-child {
  margin-right: 0;
}

.partner-transactions__form-avatar {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
}

.partner-transactions__form-avatar-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 30px;
}

.partner-transactions__form-avatar-label {
  min-width: 250px;
}

@media (max-width: 1024px) {
  .partner-transactions__form-item {
    width: calc(50% - 25px);
    margin: 0 25px 20px 0;
  }
  .partner-transactions__header {
    margin: 0 25px 20px 0;
  }
  .partner-transactions__form-btns {
    width: calc(50% - 25px);
    display: flex;
    justify-content: center;
    margin: 0 15px 50px 0;
  }
  .partner-transactions__form-btns-item {
    margin-right: 15px;
  }
}

.partner-transactions__notice {
  border-radius: 4px;
  border: 1px solid #0a1c33;
  padding: 15px 15px;
  margin: 0 auto 30px auto;
  text-align: center;
  font-weight: 500;
  width: 520px;
}

@media (max-width: 850px) {
  .partner-transactions__form-item {
    width: 100%;
    margin: 0 25px 20px 0;
  }
  .partner-transactions__form-btns {
    width: 100%;
    margin: 0 0 25px 0;
  }
}

@media (max-width: 599px) {
  .partner-transactions__form-item {
    width: 100%;
    margin: 0 0px 20px 0;
  }
  .partner-transactions__header {
    margin: 0 0px 20px 0;
  }
  .partner-transactions__form-avatar {
    align-items: initial;
    flex-direction: column;
    margin: 0;
  }
  .partner-transactions__form-avatar-img {
    width: 75px;
    height: 75px;
    margin-right: 15px;
    margin-bottom: 20px;
  }
  .partner-transactions__notice {
    width: 100%;
    margin: 0 0 20px 0;
  }
}

.partner-transactions select,
.partner-transactions input {
  min-height: 42px;
  padding: 8px 15px;
}
.partner-transactions select {
  min-width: 150px;
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  background: url('../../../assets/images/arrow.png') no-repeat right #fff;
  background-repeat: no-repeat;
  background-size: 10px 10px;
  background-position: calc(100% - 15px);
  cursor: pointer;
}
