.PollsManagement {
  display: flex;
  flex-direction: column;
}

.PollsManagement__list {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
  padding: 0;
  list-style: none;
}

.PollsManagement__row {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.PollsManagement__item {
  display: flex;
  align-items: flex-start;
  margin-left: -0.5rem;
  align-items: center;
}

.PollsManagement__col {
  flex-basis: 50%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.PollsManagement__fileActions {
  white-space: nowrap;
}

.PollsManagement__buttonIcon {
  margin-top: -5px;
  margin-left: 5px;
}

.PollsManagement__button {
  display: block;
  margin-top: 0.5rem;
}
